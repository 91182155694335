/** @format */

export const utilityMapHsCodeToEhr = (code: string): string => {
  if (code === "6429215") return "103732";
  if (code === "1445965") return "616237";
  if (code === "6812481") return "137709";
  if (code === "1037512") return "590438";
  if (code === "4344882") return "304879";
  if (code === "2531679") return "323804";
  if (code === "7059199") return "387133";
  return "788645";
};
