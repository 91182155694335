/** @format */

import { SearchOutlined } from "@ant-design/icons";
import { Cascader, Col, Empty, Row, Slider, Spin } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { apiTravelList } from "../../../../api/apiTravels";
import { API_ENDPOINTS } from "../../../../constants/api";
import { TUser } from "../../../../redux-modules/declarations/maps/auth";
import { TTravel } from "../../../../redux-modules/declarations/maps/travels";
import { BaseColor } from "../../../../theme/maps/Colors";
import apiClient from "../../../../utils/apiClient";
import { utilityColumnsToUserReadable } from "../../../../utils/strings/stringToHumanReadable";
import { UICheckbox } from "../../../ui/Checkbox";
import { UIDatePicker } from "../../../ui/DatePicker";
import UIModal from "../../../ui/Modal";
import { UIRangePicker } from "../../../ui/RangePicker";
import { UISelect } from "../../../ui/Select";
import { UIText } from "../../../ui/Text";
import { UITitle } from "../../../ui/Title";

export const ExportTravelAndExpenseModal = ({
  visible,
  onCancel,
  onExport,
}: {
  visible: boolean;
  onCancel: () => void;
  onExport: (e: {
    date_from?: string;
    date_to?: string;
    travel_id?: string;
    user_ids?: string[];
    soglia_from?: string;
    soglia_to?: string;
  }) => void;
}) => {
  interface Option {
    value: string | number;
    label: string;
    children?: Option[];
  }
  const [selectedDate, setSelectedDate] = useState<moment.Moment[]>([
    moment().startOf("month"),
    moment().endOf("month"),
  ]);
  const [selectedTravel, setSelectedTravel] = useState<string | undefined>("none");
  const [travelList, setTravelList] = useState<TTravel[]>([]);
  const [isUserLoading, setIsUserLoading] = useState(false);
  const [options, setOptions] = useState<Option[]>([]);
  const [userList, setUserList] = useState<TUser[]>([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [sliderValue, setSliderValue] = useState<number[]>([0, 200]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsUserLoading(true);
    apiClient.get(API_ENDPOINTS.USERS_LIST + "?all=true").then(res => {
      setUserList(res.data.data);
      setIsUserLoading(false);
    });
  }, []);

  useEffect(() => {
    if (userList) {
      let options: Option[] = [];
      userList.forEach(user => {
        //controllo se esiste già il gruppo
        user.groups.forEach(group => {
          if (options.find(el => el.value == "group_" + group.id)) {
            if (
              options
                .find(el => el.value == "group_" + group.id)
                ?.children?.find(e => e.value == "department_" + user.department.id)
            ) {
              options
                .find(el => el.value == "group_" + group.id)
                ?.children?.find(e => e.value == "department_" + user.department.id)
                ?.children?.push({
                  value: user.id,
                  label: user.surname + " " + user.name,
                }) || console.log("");
            } else {
              options
                .find(el => el.value == "group_" + group.id)
                ?.children?.push({
                  value: "department_" + user.department.id,
                  label: group.name + " - " + user.department.name,
                  children: [
                    {
                      value: user.id,
                      label: user.surname + " " + user.name,
                    },
                  ],
                }) || console.log("");
            }
          } else {
            options.push({
              value: "group_" + group.id,
              label: group.name,
              children: [
                {
                  value: "department_" + user.department.id,
                  label: group.name + " - " + user.department.name,
                  children: [{ value: user.id, label: user.surname + " " + user.name }],
                },
              ],
            });
          }
        });
      });
      setOptions(options);
    }
  }, [userList]);

  const onChangeFilters = (value: unknown) => {
    //@ts-ignore
    setSelectedValues(value);
    console.log(value);
  };

  const getUsersArray = (array: string[]): string[] => {
    let users: string[] = [];
    if (array.length > 0) {
      array.forEach((value: string) => {
        // se gruppo
        if (value.length == 1) {
          let gruppo = value[0].split("_")[1];
          userList.forEach(user => {
            if (user.groups.find(group => group.id == gruppo)) {
              users.push(user.id);
            }
          });
        }
        // se dipartimento
        else if (value.length == 2) {
          let gruppo = value[0].split("_")[1];
          let dipartimento = value[1].split("_")[1];
          userList.forEach(user => {
            if (
              user.groups.find(group => group.id == gruppo) &&
              user.department.id == dipartimento
            ) {
              users.push(user.id);
            }
          });
        }
        // se utente
        else if (value.length == 3) {
          users.push(value[2]);
        }
      });
    } else {
      users = userList.map(user => user.id);
    }

    return users;
  };

  const handleExport = () => {
    setIsLoading(true);
    if (selectedTravel == "none") {
      onExport({
        date_from: selectedDate[0].format("YYYY-MM-DD"),
        date_to: selectedDate[1].format("YYYY-MM-DD"),
        user_ids: getUsersArray(selectedValues),
        soglia_from: `${sliderValue[0]}`,
        soglia_to: `${sliderValue[1]}`,
      });
    } else {
      onExport({
        travel_id: `${selectedTravel}`,
        date_from: selectedDate[0].format("YYYY-MM-DD"),
        date_to: selectedDate[1].format("YYYY-MM-DD"),
      });
    }
    onCancel();
    setIsLoading(false);
  };

  useEffect(() => {
    console.log(selectedTravel);
  }, [selectedTravel]);

  useEffect(() => {
    apiTravelList({
      date_from: selectedDate[0].format("YYYY-MM-DD"),
      date_to: selectedDate[1].format("YYYY-MM-DD"),
    }).then(r => {
      //@ts-ignore
      setTravelList(r.data.data);
    });
  }, [selectedDate]);

  return (
    <UIModal
      title={"Esporta Viaggi e Spese"}
      visible={visible}
      onOk={handleExport}
      okText="Esporta"
      confirmLoading={isLoading}
      maskClosable={false}
      okButtonProps={{
        style: { borderRadius: "7px", backgroundColor: BaseColor, borderColor: BaseColor },
      }}
      cancelButtonProps={{ style: { borderRadius: "7px" } }}
      onCancel={onCancel}
    >
      <Row>
        <Col>
          <UITitle level={4}>Seleziona i filtri per l'esportazione</UITitle>
        </Col>
      </Row>
      <Row style={{ flexFlow: "column" }}>
        <Col>
          <UIText>Seleziona il periodo:</UIText>
        </Col>
        <Col style={{ marginRight: "10px" }}>
          <UIRangePicker
            //@ts-ignore
            value={selectedDate}
            format="DD/MM/YYYY"
            //@ts-ignore
            onChange={(v: Moment | null): void => setSelectedDate(v)}
          />
        </Col>
      </Row>
      <Row style={{ flexFlow: "column", marginTop: "5px" }}>
        <Col>
          <UIText>Seleziona il viaggio:</UIText>
        </Col>
        <Col style={{ marginRight: "10px" }} span={24}>
          <UISelect
            style={{ width: "100%" }}
            onSelect={
              //@ts-ignore
              e => setSelectedTravel(e)
            }
            defaultValue={selectedTravel}
          >
            <UISelect.Option value={"none"}>-- Nessuno --</UISelect.Option>
            {travelList.map((t: TTravel) => (
              <UISelect.Option key={t.id} value={t.id}>
                {t.name}
              </UISelect.Option>
            ))}
          </UISelect>
        </Col>
      </Row>
      {selectedTravel == "none" && (
        <>
          <Row style={{ flexFlow: "column", marginTop: "5px" }}>
            <Col>
              <UIText>Seleziona gli utenti da esportare:</UIText>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <CustomCascader
                style={{ width: "100%" }}
                loading={!!isUserLoading}
                notFoundContent={
                  isUserLoading ? (
                    <Spin size="small" />
                  ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  )
                }
                showSearch
                options={options}
                //@ts-ignore
                onChange={onChangeFilters}
                value={selectedValues}
                multiple
                placeholder={
                  <span>
                    <SearchOutlined style={{ marginRight: "5px" }} /> - Tutti -
                  </span>
                }
              />
            </Col>
          </Row>
          <Row style={{ flexFlow: "column", marginTop: "5px" }}>
            <Col>
              <UIText>Seleziona la soglia di spesa:</UIText>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Slider
                range={{ draggableTrack: true }}
                //@ts-ignore
                onChange={e => setSliderValue(e)}
                min={0}
                max={1000}
                step={10}
                //@ts-ignore
                defaultValue={sliderValue}
                marks={{
                  0: "0€",
                  1000: ">1000€",
                }}
              />
            </Col>
          </Row>
        </>
      )}
    </UIModal>
  );
};

const CustomCascader = styled(Cascader)`
  .ant-select-selector {
    border-radius: 7px !important;
  }
`;
