/** @format */

import { Col, Image, Row, Skeleton } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { actionPresenceCreateRequest } from "../../../redux-modules/actions/actionsPresences";
import { selectorPresenceUpdated } from "../../../redux-modules/selectors/selectorsUI";
import { Colors } from "../../../theme/maps/Colors";
import { UIButton } from "../../ui/Button";
import { UICard } from "../../ui/Card";
import { UIImage } from "../../ui/Image";
import { UITitle } from "../../ui/Title";
import { CusDiv, StyledQRReader } from "../ViewBadge";
import logoPev from "../../../assets/images/pev.png";
import logoPevGo from "../../../assets/images/pevgo.png";
import { utilityMapHsCodeToEhr } from "../../../utils/format/mapHrSempliceCodeToEhrCode";

interface Props {}

type TBadge = "hrsemplice" | "easyhr";

export const ViewDoubleBadge = ({}: Props): JSX.Element => {
  const [badgeType, setBadgeType] = useState<TBadge | undefined>();
  const refIframe = useRef<HTMLIFrameElement>(null);

  const handleBadgeClick = (type: TBadge) => setBadgeType(type);

  const [deviceCode, setDeviceCode] = useState("");
  const [writingDeviceCode, setWritingDeviceCode] = useState("");
  const [data, setData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [facingMode, setFacingMode] = useState("user");
  const dispatch = useDispatch();
  const isPresenceCreated = useSelector(selectorPresenceUpdated);

  const sendPinToEhr = () => {
    var receiver = refIframe.current?.contentWindow;
    const deviceCode = window.localStorage.getItem("deviceCode");
    if (!!receiver && !!deviceCode) {
      receiver.postMessage(utilityMapHsCodeToEhr(deviceCode), "*");
    }
  };

  const switchFacingMode = (): void => {
    setFacingMode(facingMode === "user" ? "environment" : "user");
  };
  //@ts-ignore
  const handleResult = (res, err): void => {
    if (!!res) {
      if (!isBase64(res.text)) {
        setIsLoading(true);
        dispatch(
          actionPresenceCreateRequest({
            ...JSON.parse(res.text),
            origin: "ipad",
            device_code: deviceCode,
          }),
        );
      }
    }
  };

  const insertDeviceCode = () => {
    setDeviceCode(writingDeviceCode);
    window.localStorage.setItem("deviceCode", writingDeviceCode);
  };

  const dialerClick = (v: string | number): void => {
    if (typeof v === "string") {
      if (v == "clear") {
        setWritingDeviceCode("");
      } else {
        setWritingDeviceCode(writingDeviceCode.substring(0, writingDeviceCode.length - 1));
      }
    } else {
      setWritingDeviceCode(`${writingDeviceCode}` + `${v}`);
    }
  };

  useEffect(() => {
    if (!!isPresenceCreated) {
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    }
  }, [isPresenceCreated]);
  useEffect(() => {
    if (!!data) alert(data);
  }, [data]);
  useEffect(() => {
    const currentCode = window.localStorage.getItem("deviceCode");
    if (!!currentCode) setDeviceCode(currentCode);
  }, []);

  return (
    <>
      <Row>
        <Col span={24}>
          {typeof badgeType === "undefined" ? (
            <>
              <Row justify="center">
                <Col>
                  <UITitle level={1} color={Colors.gray}>
                    Seleziona una tipologia di timbratura
                  </UITitle>
                </Col>
              </Row>
              <Row
                justify="center"
                gutter={[50, 50]}
                style={{
                  marginTop: "20px",
                  display: "flex",
                  flexDirection: "column",
                  alignContent: "center",
                }}
              >
                <Col>
                  <CustomUIButton
                    type="primary"
                    size="large"
                    style={{ height: "100%", border: "none", backgroundColor: "transparent" }}
                    onClick={() => handleBadgeClick("easyhr")}
                  >
                    <Image
                      src={logoPev}
                      style={{ width: "250px", height: "auto", borderRadius: "7px" }}
                    />
                  </CustomUIButton>
                </Col>
                <Col>
                  <CustomUIButton
                    type="primary"
                    size="large"
                    style={{ height: "100%", border: "none", backgroundColor: "transparent" }}
                    onClick={() => handleBadgeClick("hrsemplice")}
                  >
                    <Image
                      src={logoPevGo}
                      style={{ width: "250px", height: "auto", borderRadius: "7px" }}
                    />
                  </CustomUIButton>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row justify="center">
                <Col>
                  <CustomUIButton
                    type="primary"
                    size="large"
                    onClick={() => setBadgeType(undefined)}
                  >
                    Nuova timbratura
                  </CustomUIButton>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
      {typeof badgeType !== "undefined" && (
        <Row justify="center">
          <Col span={20}>
            <UICard style={{ width: "100%", height: "70vh", marginTop: "15px" }}>
              {badgeType === "hrsemplice" && (
                <>
                  {!deviceCode && (
                    <>
                      <Row justify="center" gutter={[16, 16]}>
                        <Col>
                          <UITitle level={3}>Inserisci il codice del dispositivo</UITitle>
                        </Col>
                      </Row>
                      <Row justify="center" gutter={[16, 16]}>
                        <Col span={24}>
                          <CusDiv>
                            <table id="dialer_table">
                              <tr>
                                <td id="dialer_input_td" colSpan={3}>
                                  <input
                                    style={{ textAlign: "center" }}
                                    type="number"
                                    value={writingDeviceCode}
                                    placeholder=""
                                  />
                                </td>
                              </tr>
                              <tr className="dialer_num_tr">
                                <td className="dialer_num" onClick={() => dialerClick(1)}>
                                  1
                                </td>
                                <td className="dialer_num" onClick={() => dialerClick(2)}>
                                  2
                                </td>
                                <td className="dialer_num" onClick={() => dialerClick(3)}>
                                  3
                                </td>
                              </tr>
                              <tr className="dialer_num_tr">
                                <td className="dialer_num" onClick={() => dialerClick(4)}>
                                  4
                                </td>
                                <td className="dialer_num" onClick={() => dialerClick(5)}>
                                  5
                                </td>
                                <td className="dialer_num" onClick={() => dialerClick(6)}>
                                  6
                                </td>
                              </tr>
                              <tr className="dialer_num_tr">
                                <td className="dialer_num" onClick={() => dialerClick(7)}>
                                  7
                                </td>
                                <td className="dialer_num" onClick={() => dialerClick(8)}>
                                  8
                                </td>
                                <td className="dialer_num" onClick={() => dialerClick(9)}>
                                  9
                                </td>
                              </tr>
                              <tr className="dialer_num_tr">
                                <td className="dialer_del_td">
                                  <img
                                    alt="clear"
                                    onClick={() => dialerClick("clear")}
                                    src="data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJlcmFzZXIiIHJvbGU9ImltZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgY2xhc3M9InN2Zy1pbmxpbmUtLWZhIGZhLWVyYXNlciBmYS13LTE2IGZhLTd4Ij48cGF0aCBmaWxsPSIjYjFiMWIxIiBkPSJNNDk3Ljk0MSAyNzMuOTQxYzE4Ljc0NS0xOC43NDUgMTguNzQ1LTQ5LjEzNyAwLTY3Ljg4MmwtMTYwLTE2MGMtMTguNzQ1LTE4Ljc0NS00OS4xMzYtMTguNzQ2LTY3Ljg4MyAwbC0yNTYgMjU2Yy0xOC43NDUgMTguNzQ1LTE4Ljc0NSA0OS4xMzcgMCA2Ny44ODJsOTYgOTZBNDguMDA0IDQ4LjAwNCAwIDAgMCAxNDQgNDgwaDM1NmM2LjYyNyAwIDEyLTUuMzczIDEyLTEydi00MGMwLTYuNjI3LTUuMzczLTEyLTEyLTEySDM1NS44ODNsMTQyLjA1OC0xNDIuMDU5em0tMzAyLjYyNy02Mi42MjdsMTM3LjM3MyAxMzcuMzczTDI2NS4zNzMgNDE2SDE1MC42MjhsLTgwLTgwIDEyNC42ODYtMTI0LjY4NnoiIGNsYXNzPSIiPjwvcGF0aD48L3N2Zz4="
                                    width="22px"
                                    title="Clear"
                                  />
                                </td>
                                <td className="dialer_num" onClick={() => dialerClick(0)}>
                                  0
                                </td>
                                <td className="dialer_del_td">
                                  <img
                                    alt="delete"
                                    onClick={() => dialerClick("delete")}
                                    src="data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhciIgZGF0YS1pY29uPSJiYWNrc3BhY2UiIHJvbGU9ImltZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNjQwIDUxMiIgY2xhc3M9InN2Zy1pbmxpbmUtLWZhIGZhLWJhY2tzcGFjZSBmYS13LTIwIGZhLTd4Ij48cGF0aCBmaWxsPSIjREMxQTU5IiBkPSJNNDY5LjY1IDE4MS42NWwtMTEuMzEtMTEuMzFjLTYuMjUtNi4yNS0xNi4zOC02LjI1LTIyLjYzIDBMMzg0IDIyMi4wNmwtNTEuNzItNTEuNzJjLTYuMjUtNi4yNS0xNi4zOC02LjI1LTIyLjYzIDBsLTExLjMxIDExLjMxYy02LjI1IDYuMjUtNi4yNSAxNi4zOCAwIDIyLjYzTDM1MC4wNiAyNTZsLTUxLjcyIDUxLjcyYy02LjI1IDYuMjUtNi4yNSAxNi4zOCAwIDIyLjYzbDExLjMxIDExLjMxYzYuMjUgNi4yNSAxNi4zOCA2LjI1IDIyLjYzIDBMMzg0IDI4OS45NGw1MS43MiA1MS43MmM2LjI1IDYuMjUgMTYuMzggNi4yNSAyMi42MyAwbDExLjMxLTExLjMxYzYuMjUtNi4yNSA2LjI1LTE2LjM4IDAtMjIuNjNMNDE3Ljk0IDI1Nmw1MS43Mi01MS43MmM2LjI0LTYuMjUgNi4yNC0xNi4zOC0uMDEtMjIuNjN6TTU3NiA2NEgyMDUuMjZDMTg4LjI4IDY0IDE3MiA3MC43NCAxNjAgODIuNzRMOS4zNyAyMzMuMzdjLTEyLjUgMTIuNS0xMi41IDMyLjc2IDAgNDUuMjVMMTYwIDQyOS4yNWMxMiAxMiAyOC4yOCAxOC43NSA0NS4yNSAxOC43NUg1NzZjMzUuMzUgMCA2NC0yOC42NSA2NC02NFYxMjhjMC0zNS4zNS0yOC42NS02NC02NC02NHptMTYgMzIwYzAgOC44Mi03LjE4IDE2LTE2IDE2SDIwNS4yNmMtNC4yNyAwLTguMjktMS42Ni0xMS4zMS00LjY5TDU0LjYzIDI1NmwxMzkuMzEtMTM5LjMxYzMuMDItMy4wMiA3LjA0LTQuNjkgMTEuMzEtNC42OUg1NzZjOC44MiAwIDE2IDcuMTggMTYgMTZ2MjU2eiIgY2xhc3M9IiI+PC9wYXRoPjwvc3ZnPg=="
                                    width="25px"
                                    title="Delete"
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={3}>
                                  <span id="dialer_call_btn_td" onClick={insertDeviceCode}>
                                    OK
                                  </span>
                                </td>
                              </tr>
                            </table>
                          </CusDiv>
                        </Col>
                      </Row>
                    </>
                  )}
                  {!!deviceCode && (
                    <>
                      <Row justify="center" gutter={[16, 16]}>
                        <Col span={24}>
                          <UICard
                            style={{
                              marginBottom: "20px",
                              border: "none",
                              display: isLoading ? "block" : "none",
                            }}
                          >
                            <Skeleton active />
                            <Skeleton active />
                          </UICard>
                          {!isLoading && (
                            <StyledQRReader
                              containerStyle={{ display: isLoading ? "none" : "block" }}
                              videoContainerStyle={{ display: "contents" }}
                              videoStyle={{
                                width: "50%",
                                height: "45%",
                                left: "25%",
                                position: "relative",
                              }}
                              onResult={handleResult}
                              constraints={{ facingMode: facingMode }}
                            />
                          )}
                        </Col>
                      </Row>
                    </>
                  )}
                </>
              )}
              {badgeType === "easyhr" && (
                <iframe
                  ref={refIframe}
                  style={{ width: "100%", height: "65vh" }}
                  src={"https://prezzemoloevitale.easyhr.it/stamp2"}
                  allow="camera;microphone;cross-origin;"
                  onLoad={() => sendPinToEhr()}
                >
                  <style>
                    {`#logo img {
                        width: "100px";
                    }
                    `}
                  </style>
                </iframe>
              )}
            </UICard>
          </Col>
        </Row>
      )}
    </>
  );
};

const CustomUIButton = styled(UIButton)`
  font-size: 40px;
  height: 70px;
  font-weight: 800;
`;

export function isBase64(str: string) {
  if (str === "" || str.trim() === "") {
    return false;
  }
  try {
    return btoa(atob(str)) == str;
  } catch (err) {
    return false;
  }
}
